import {
  IChangeStatusInBulk,
  ICreateSubscription,
  ICreateSubscriptionByTag,
  IUser
} from '@sdk/contracts';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { message } from 'antd';
import { useTranslation } from 'react-i18next';

import { useStudentPreview } from '@/hooks';
import { sdk } from '@/services';

const MyAcademyStudentsService = sdk.getService('MyAcademyStudentsService');
const SubscriptionService = sdk.getService('SubscriptionService');
const MyAcademyService = sdk.getService('MyAcademyService');
const UserService = sdk.getService('UserService');

export const useMutateStudents = () => {
  const queryClient = useQueryClient();
  const onSuccess = () => queryClient.invalidateQueries({ queryKey: ['students'] });
  const { t } = useTranslation();
  const { setUserId } = useStudentPreview();

  const { mutate: createStudent, isPending: isCreating } = useMutation({
    mutationFn: (newStudent: Partial<IUser>) => MyAcademyStudentsService.createStudent(newStudent),
    onSuccess: data => {
      if (data.alreadyExists) {
        message.error(t('USER_ALREADY_EXISTS'));
        setUserId(data._id);
        return;
      }
      onSuccess();
      message.success(t('NEW_STUDENT_ADDED'));
    }
  });

  const { mutate: updateStudent, isPending: isUpdating } = useMutation({
    mutationFn: (updatedStudent: Partial<IUser>) =>
      MyAcademyStudentsService.updateStudent(updatedStudent),
    onSuccess: () => {
      onSuccess();
      message.success(t('STUDENT_UPDATED_SUCCESSFULLY'));
    }
  });

  const { mutate: removeStudent, isPending: isRemoving } = useMutation({
    mutationFn: (userId: string) => MyAcademyService.removeUser(userId),
    onSuccess: () => {
      onSuccess();
      message.warning(t('USER_DELETED_SUCCESSFULLY'));
    }
  });

  const { mutate: toggleActivateStudent, isPending: isToggling } = useMutation({
    mutationFn: (userId: string) => UserService.toggleActivateUser(userId),
    onSuccess
  });

  const { mutate: createSubscription, isPending: isCreatingSubscription } = useMutation({
    mutationFn: (create: ICreateSubscription) => SubscriptionService.create(create),
    onSuccess
  });

  const { mutate: createSubscriptionByTag, isPending: isCreatingSubscriptionByTag } = useMutation({
    mutationFn: (create: ICreateSubscriptionByTag) => SubscriptionService.createByTag(create),
    onSuccess
  });

  const { mutate: removeSubscription, isPending: isRemovingSubscription } = useMutation({
    mutationFn: (subscriptionId: string) => SubscriptionService.remove(subscriptionId),
    onSuccess
  });

  const { mutate: changeStatusInBulk, isPending: isChangingStatusInBulk } = useMutation({
    mutationFn: (updateStatusInBulk: IChangeStatusInBulk) =>
      MyAcademyStudentsService.changeStatusInBulk(updateStatusInBulk),
    onSuccess: () => queryClient.invalidateQueries({ queryKey: ['users'] })
  });

  return {
    createStudent,
    updateStudent,
    removeStudent,
    toggleActivateStudent,
    createSubscription,
    createSubscriptionByTag,
    removeSubscription,
    changeStatusInBulk,
    isWorking:
      isCreating ||
      isUpdating ||
      isRemoving ||
      isToggling ||
      isCreatingSubscription ||
      isCreatingSubscriptionByTag ||
      isRemovingSubscription ||
      isChangingStatusInBulk
  };
};
