import { RequestService } from '../request';

import { ICreateLiveClassVote, ILiveClassVote } from './live-class-vote.contract';

export class LiveClassVoteService extends RequestService {
  constructor(apiUrl: string) {
    super(apiUrl);
  }

  async checkLiveClassVote() {
    return this.fetcher<ILiveClassVote>(`/live-class-vote/check`);
  }

  async createLiveClassVote(data: ICreateLiveClassVote) {
    return this.poster<ILiveClassVote, ICreateLiveClassVote>(`/live-class-vote`, data);
  }
}
