import { EStudentPaymentStatus } from '@constants';
import { IStudentStats, IUserStatsFilters } from '@sdk/contracts';
import { useQuery } from '@tanstack/react-query';
import { SorterResult } from 'antd/es/table/interface';
import { create as createStore } from 'zustand';

import { sdk } from '@/services';

const INITIAL_PAGE = 1;
const INITIAL_LIMIT = 10;
const INITIAL_FILTERS: IUserStatsFilters = {
  paymentStatus: Object.values(EStudentPaymentStatus).map(status => status)
};

const UserService = sdk.getService('UserService');

interface IStore {
  page: number;
  limit: number;
  search: string;
  filter?: IUserStatsFilters;
  sort?: SorterResult<IStudentStats>;
  setSearch: (search: string) => void;
  setPageAndLimit: (page: number, limit: number) => void;
  setFilter: (filter: IUserStatsFilters) => void;
  setSort: (sort: SorterResult<IStudentStats>) => void;
}

const store = createStore<IStore>(set => ({
  search: '',
  page: INITIAL_PAGE,
  limit: INITIAL_LIMIT,
  filter: INITIAL_FILTERS,
  setSearch: (search: string) => set(state => ({ ...state, search, page: INITIAL_PAGE })),
  setPageAndLimit: (page: number, limit: number) => set(state => ({ ...state, page, limit })),
  setFilter: (filter: IUserStatsFilters) =>
    set(state => ({ ...state, page: INITIAL_PAGE, filter })),
  setSort: (sort: SorterResult<IStudentStats>) => set(state => ({ ...state, sort }))
}));

export const useStudentsStatsList = () => {
  const { page, limit, search, setSearch, setPageAndLimit, filter, setFilter, sort, setSort } =
    store();

  const queryKey = [
    'users',
    'stats',
    'students',
    `page-${page}`,
    `limit-${limit}`,
    search,
    filter,
    sort
  ];

  const { data, error, isLoading, isFetching } = useQuery({
    queryKey,
    refetchOnWindowFocus: false,
    queryFn: () => UserService.getStudentsStats(search, { page, limit }, filter, sort)
  });

  const resetSearchResults = () => setSearch('');

  const pagination = data && {
    page: data.page,
    limit: data.limit,
    hasNextPage: data.hasNextPage,
    hasPrevPage: data.hasPrevPage,
    nextPage: data.nextPage,
    pagingCounter: data.pagingCounter,
    prevPage: data.prevPage,
    totalDocs: data.totalDocs,
    totalPages: data.totalPages
  };

  return {
    students: data?.docs,
    isLoading: isFetching || isLoading,
    error,
    pagination,
    filter,
    sort,
    setSearch,
    setPageAndLimit,
    setFilter,
    setSort,
    resetSearchResults
  };
};
