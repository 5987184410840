import { AnalyticsTrackEvents } from '@constants';
import { dateProvider } from '@utils';
import { Button, Divider } from 'antd';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import {
  DateDayWrapper,
  DateMonthWrapper,
  DateWrapper,
  EmptyWrapper,
  EventTitleWrapper,
  InfoWrapper,
  ItemWrapper,
  TimeWrapper,
  Wrapper
} from './UpcomingEvents.styles';

import { Icon } from '@/components/Icon';
import { useSegment } from '@/hooks';
import { useMyEventsList } from '@/store';

export const UpcomingEvents = () => {
  const { events } = useMyEventsList();
  const { t } = useTranslation('StudentCourseSummaryTranslations');
  const navigateTo = useNavigate();
  const { fireEvent } = useSegment();

  const fireWidgetEvent = () =>
    fireEvent(AnalyticsTrackEvents.HOME_WIDGET, {
      widgetName: 'EVENTS'
    });

  const goToEvents = () => {
    navigateTo('/content/calendar');
    fireWidgetEvent();
  };
  const goToEvent = (eventId: string) => {
    navigateTo(`/content/calendar/${eventId}`);
    fireWidgetEvent();
  };

  return (
    <Wrapper>
      {events && events.length > 0 ? (
        <>
          {events.map(event => {
            const isFinalEvent = event === events[events.length - 1];
            const { startDate, endDate } = event;
            const utcStartDate = dateProvider.zonedTimeToUtc(startDate);
            const utcEndDate = dateProvider.zonedTimeToUtc(endDate);

            return (
              <>
                <ItemWrapper key={event._id} onClick={() => goToEvent(event._id)}>
                  <DateWrapper>
                    <DateDayWrapper>{dateProvider.format(utcStartDate, 'dd')}</DateDayWrapper>
                    <DateMonthWrapper>
                      {dateProvider.format(utcStartDate, 'MMM').toLocaleUpperCase()}
                    </DateMonthWrapper>
                  </DateWrapper>
                  <InfoWrapper>
                    <TimeWrapper>
                      <Icon i="Time" />
                      <div>{`${dateProvider.format(utcStartDate, 'HH:mm')} - ${dateProvider.format(
                        utcEndDate,
                        'HH:mm'
                      )}`}</div>
                    </TimeWrapper>
                    <EventTitleWrapper>{event.title}</EventTitleWrapper>
                  </InfoWrapper>
                  <Icon size={24} i="ChevronRight" />
                </ItemWrapper>
                {!isFinalEvent && <Divider type="horizontal" />}
              </>
            );
          })}
        </>
      ) : (
        <EmptyWrapper>
          <div>{t('NO_LIVE_EVENTS')}</div>
          <Button onClick={goToEvents} icon={<Icon i="CalendarAdd" />}>
            {t('SEARCH_LIVE_EVENTS')}
          </Button>
        </EmptyWrapper>
      )}
    </Wrapper>
  );
};
