import { createQueryString } from '@utils';
import { SorterResult } from 'antd/es/table/interface';

import { IPaginatedResponse, IPagination } from '../common';
import { RequestService } from '../request/request.service';
import { ISocketUser } from '../socket';

import {
  IReportFilters,
  ITeachersContentReport,
  ITeachersReportFilters,
  IUser,
  IUserContractSign,
  IUserReport,
  IStudentStats,
  IUserStatsFilters,
  IUpdateStudentPaymentStatus,
  IHubspotUser,
  ITeacherStudentsFilters,
  IHubspotVisitorToken
} from './user.contract';

export class UserService extends RequestService {
  public constructor(apiUrl: string) {
    super(apiUrl);
  }

  public getMe = async () => {
    return this.fetcher<IUser>('/me');
  };

  public updateMe = async (updated: Partial<IUser>) => {
    return this.putter<IUser, Partial<IUser>>('/me', updated);
  };

  public moreInfo = async () => {
    return this.putter('/me/more-info');
  };

  public getUsers = async (search?: string, pagination?: IPagination) => {
    const query = createQueryString({
      search,
      limit: pagination?.limit,
      page: pagination?.page
    });
    return this.fetcher<IPaginatedResponse<IUser>>(`/users?${query}`);
  };

  public createUser = async (user: IUser) => {
    return this.poster<IUser, IUser>('/users', user);
  };

  public getUser = async (userId: string) => {
    return this.fetcher<IUser>(`/users/${userId}`);
  };

  public updateUser = async (user: Partial<IUser>) => {
    return this.putter<IUser, Partial<IUser>>(`/users/${user._id}`, user);
  };

  public removeUser = async (userId: string) => {
    return this.deleter(`/users/${userId}`);
  };

  public toggleActivateUser = async (userId: string) => {
    return this.putter(`/users/toggle-activate/${userId}`);
  };

  public getUsersReport = async (
    search?: string,
    pagination?: IPagination,
    filters?: IReportFilters
  ) => {
    const query = createQueryString({
      search,
      limit: pagination?.limit,
      page: pagination?.page,
      filters
    });
    return this.fetcher<IPaginatedResponse<IUserReport>>(`/users/stats/reports?${query}`);
  };

  public requestAllUsersReport = async (filters?: IReportFilters) => {
    const query = createQueryString({ filters });
    return this.poster(`/users/stats/all-reports?${query}`);
  };

  public sendReferralInvitation = async (email: string) => {
    return this.poster(`/users/referral/invite`, { email });
  };

  public generateUserContract = async (user: Partial<IUser>) => {
    return this.poster<IUserContractSign, Partial<IUser>>(`/users/contract`, user);
  };

  public getTeachersContentReport = async (filters?: ITeachersReportFilters) => {
    const query = createQueryString({ filters });
    return this.fetcher<ITeachersContentReport[]>(`/users/stats/teachers-content?${query}`);
  };

  public getAITeachers = async () => {
    return this.fetcher<IUser[]>(`/users/ai-teachers`);
  };

  public createAITeacher = async (teacher: Partial<IUser>) => {
    return this.poster<IUser, Partial<IUser>>(`/users`, teacher);
  };

  public updateAITeacher = async (teacher: Partial<IUser>) => {
    return this.putter<IUser, Partial<IUser>>(`/users/${teacher._id}`, teacher);
  };

  public getStudentsStats = async (
    search?: string,
    pagination?: IPagination,
    filters?: IUserStatsFilters,
    sort?: SorterResult<IStudentStats>
  ) => {
    const query = createQueryString({
      search,
      limit: pagination?.limit,
      page: pagination?.page,
      filters,
      sort: sort?.field ? { sort: sort.field, order: sort.order } : undefined
    });
    return this.fetcher<IPaginatedResponse<IStudentStats>>(`/users/stats/students?${query}`);
  };

  public getTeacherStudentsStats = async (
    search?: string,
    pagination?: IPagination,
    filters?: ITeacherStudentsFilters
  ) => {
    const query = createQueryString({
      search,
      limit: pagination?.limit,
      page: pagination?.page,
      filters
    });
    return this.fetcher<IPaginatedResponse<IStudentStats>>(`/courses/teacher/stats?${query}`);
  };

  public updateStudentPaymentStatus = async (user: IUpdateStudentPaymentStatus) => {
    return this.putter<IUser, IUpdateStudentPaymentStatus>(
      `/users/${user._id}/payment-status`,
      user
    );
  };

  public getAllHubspotContactsBy = async (search: string) => {
    const query = createQueryString({
      search
    });
    return this.fetcher<IHubspotUser[]>(`/users/hubspot-selector?${query}`);
  };

  public getHubspotVisitorToken = async () => {
    return this.fetcher<IHubspotVisitorToken>(`/users/hubspot-visitor-token`);
  };

  public getOnlineUsers = async () => {
    return this.fetcher<ISocketUser[]>('/users/online/users');
  };
}
