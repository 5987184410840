import { Divider } from 'antd';

import { Wrapper } from './WidgetBase.styles';

import { WidgetProps } from '@/types';

type Props = {
  children: React.ReactNode;
} & WidgetProps;

export const WidgetBase: React.FC<Props> = ({
  special,
  title,
  subTitle,
  addon,
  children,
  position = '',
  headersize = 'medium',
  hoverable = false,
  backgroundcolor,
  onHeaderClick
}) => {
  return (
    <Wrapper
      hoverable={hoverable}
      headersize={headersize}
      special={special}
      backgroundcolor={backgroundcolor}
      style={{ gridArea: position }}>
      {(title || addon) && (
        <>
          <div onClick={onHeaderClick} style={{ cursor: onHeaderClick ? 'pointer' : 'auto' }}>
            <div>
              {title && <div>{title}</div>}
              {subTitle && <div className="subtitle">{subTitle}</div>}
            </div>
            {addon && <div>{addon}</div>}
          </div>
          <Divider />
        </>
      )}
      <section>{children}</section>
    </Wrapper>
  );
};
