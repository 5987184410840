import { lazy } from 'react';

import { IRoute } from '@/types';

const CoursePage = lazy(() =>
  import('@/pages/Common').then(module => ({ default: module.CoursePage }))
);

const CoursesPage = lazy(() =>
  import('@/pages/Common').then(module => ({ default: module.CoursesPage }))
);

const DashboardPage = lazy(() =>
  import('@/pages/Common').then(module => ({ default: module.DashboardPage }))
);

const CourseTagsPage = lazy(() =>
  import('@/pages/Common').then(module => ({ default: module.CourseTagsPage }))
);

const EventsPage = lazy(() =>
  import('@/pages/Common').then(module => ({ default: module.EventsPage }))
);

const EventPage = lazy(() =>
  import('@/pages/Common').then(module => ({ default: module.EventPage }))
);

const TicketsPage = lazy(() =>
  import('@/pages/Common').then(module => ({ default: module.TicketsPage }))
);

const TeachersPage = lazy(() =>
  import('@/pages/Manager').then(module => ({ default: module.TeachersPage }))
);

const StudentsPage = lazy(() =>
  import('@/pages/Manager').then(module => ({ default: module.StudentsPage }))
);

const MyAcademyPage = lazy(() =>
  import('@/pages/Manager').then(module => ({ default: module.MyAcademyPage }))
);

const QuizzesPage = lazy(() =>
  import('@/pages/Manager').then(module => ({ default: module.QuizzesPage }))
);

const QuizFormPage = lazy(() =>
  import('@/pages/Common').then(module => ({ default: module.QuizFormPage }))
);

const TutoringGroupsPage = lazy(() =>
  import('@/pages/Common').then(module => ({ default: module.TutoringGroupsPage }))
);

const TutoringGroupPage = lazy(() =>
  import('@/pages/Common').then(module => ({ default: module.TutoringGroupPage }))
);

const SectionReviewsStatsPage = lazy(() =>
  import('@/pages/Manager').then(module => ({ default: module.SectionReviewsStatsPage }))
);

const ActivityStatsPage = lazy(() =>
  import('@/pages/Manager').then(module => ({ default: module.ActivityStatsPage }))
);

const ContentTotalsPage = lazy(() =>
  import('@/pages/Manager').then(module => ({ default: module.ContentTotalsPage }))
);

const ProblemReportsPage = lazy(() =>
  import('@/pages/Manager').then(module => ({ default: module.ProblemReportsPage }))
);

const ExamGroupsPage = lazy(() =>
  import('@/pages/Manager').then(module => ({ default: module.ExamGroupsPage }))
);

const ExamGroupDetailPage = lazy(() =>
  import('@/pages/Manager').then(module => ({ default: module.ExamGroupDetail }))
);

const ScrappedExamGroupsPage = lazy(() =>
  import('@/pages/Manager').then(module => ({ default: module.ScrappedExamGroupsPage }))
);

const InternalUsersPage = lazy(() =>
  import('@/pages/Manager').then(module => ({ default: module.InternalUsersPage }))
);

const IndexationLandingsPage = lazy(() =>
  import('@/pages/Manager').then(module => ({ default: module.IndexationLandingsPage }))
);

const ChatPage = lazy(() =>
  import('@/pages/Common').then(module => ({ default: module.ChatPage }))
);

const PostPage = lazy(() =>
  import('@/pages/Common').then(module => ({ default: module.PostPage }))
);

const CourseDripContentPage = lazy(() =>
  import('@/pages/Manager').then(module => ({ default: module.CourseDripContentPage }))
);

export const MANAGER: IRoute[] = [
  {
    title: 'DASHBOARD',
    path: '/dashboard',
    icon: 'Home',
    Component: DashboardPage,
    showInMenu: true
  },
  {
    title: 'USERS',
    path: '/academy',
    icon: 'Education',
    Component: MyAcademyPage,
    showInMenu: true,
    subroutes: [
      {
        title: 'STUDENTS',
        path: 'students',
        icon: 'Undefined',
        Component: StudentsPage,
        showInMenu: true
      },
      {
        title: 'TEACHERS',
        path: 'teachers',
        icon: 'Undefined',
        Component: TeachersPage,
        showInMenu: true
      },
      {
        title: 'INTERNAL_USERS',
        path: 'internal-users',
        icon: 'Undefined',
        Component: InternalUsersPage,
        showInMenu: true
      }
    ]
  },
  {
    title: 'CONTENT',
    path: '/content',
    icon: 'Notebook',
    Component: CoursesPage,
    showInMenu: true,
    subroutes: [
      {
        title: 'COURSES',
        path: 'courses',
        icon: 'Undefined',
        Component: CoursesPage,
        showInMenu: true,
        subroutes: [
          {
            title: 'COURSES',
            path: ':_id',
            icon: 'Undefined',
            Component: CoursePage,
            showInMenu: false
          },
          {
            title: 'COURSE_DRIP_CONTENT',
            path: ':_id/drip-content',
            icon: 'Undefined',
            Component: CourseDripContentPage,
            showInMenu: false
          },
          {
            title: 'ACTIVITY_STATS',
            path: ':_id/activity-stats',
            icon: 'Undefined',
            Component: ActivityStatsPage,
            showInMenu: false
          }
        ]
      },
      {
        title: 'CATEGORIES',
        path: 'settings',
        icon: 'Undefined',
        Component: CourseTagsPage,
        showInMenu: true
      },
      {
        title: 'LIVE_EVENTS',
        path: 'calendar',
        icon: 'EventSchedule',
        Component: EventsPage,
        showInMenu: true,
        subroutes: [
          {
            title: 'LIVE_EVENTS',
            path: ':_id',
            icon: 'Undefined',
            Component: EventPage,
            showInMenu: false
          }
        ]
      },
      {
        title: 'TESTS',
        path: 'tests',
        icon: 'Undefined',
        Component: QuizzesPage,
        showInMenu: true,
        subroutes: [
          {
            title: 'TESTS',
            path: ':_id/edit',
            icon: 'Undefined',
            Component: QuizFormPage,
            showInMenu: false
          },
          {
            title: 'CREATE_TEST',
            path: 'create',
            icon: 'Undefined',
            Component: QuizFormPage,
            showInMenu: false
          },
          {
            title: 'TESTS',
            path: 'import',
            icon: 'Undefined',
            Component: QuizFormPage,
            showInMenu: false
          }
        ]
      }
    ]
  },
  {
    title: 'FOLLOW_UP',
    path: '/follow-up',
    icon: 'Undefined',
    Component: ContentTotalsPage,
    showInMenu: true,
    subroutes: [
      {
        title: 'SECTION_REVIEWS',
        path: 'section-reviews',
        icon: 'Undefined',
        Component: SectionReviewsStatsPage,
        showInMenu: true
      },
      {
        title: 'PROBLEM_REPORTS',
        path: 'problem-reports',
        icon: 'Undefined',
        Component: ProblemReportsPage,
        showInMenu: true
      },
      {
        title: 'TOTALS',
        path: 'totals',
        icon: 'Undefined',
        Component: ContentTotalsPage,
        showInMenu: true
      },
      {
        title: 'INDEXATION_LANDINGS',
        path: 'indexation-landings',
        icon: 'Undefined',
        Component: IndexationLandingsPage,
        showInMenu: true
      }
    ]
  },
  {
    title: 'COMMUNICATION',
    path: '/communication',
    icon: 'MailAll',
    Component: TicketsPage,
    showInMenu: true,
    subroutes: [
      {
        title: 'DOUBTS',
        path: 'tickets',
        icon: 'Undefined',
        Component: TicketsPage,
        showInMenu: true
      },
      {
        title: 'TUTORING_GROUPS',
        path: 'tutoring-groups',
        icon: 'Undefined',
        Component: TutoringGroupsPage,
        showInMenu: true,
        subroutes: [
          {
            title: 'TUTORING_GROUPS',
            path: ':tutoringGroupId/chat/:tutorId/:studentId',
            icon: 'Undefined',
            Component: ChatPage,
            showInMenu: false
          },
          {
            title: 'TUTORING_GROUPS',
            path: ':tutoringGroupId',
            icon: 'Undefined',
            Component: TutoringGroupPage,
            showInMenu: false
          }
        ]
      },
      {
        title: 'EXAM_GROUPS',
        path: 'exam-groups',
        icon: 'Undefined',
        Component: ExamGroupsPage,
        showInMenu: true,
        subroutes: [
          {
            title: 'EXAM_GROUPS',
            path: ':_id',
            icon: 'Undefined',
            Component: ExamGroupDetailPage,
            showInMenu: false
          },
          {
            title: 'SCRAPPED_EXAM_GROUPS',
            path: 'scrapped',
            icon: 'Undefined',
            Component: ScrappedExamGroupsPage,
            showInMenu: false
          }
        ]
      }
    ]
  },
  {
    title: 'POST',
    path: '/post/:slug',
    icon: 'Undefined',
    Component: PostPage,
    showInMenu: false
  }
];
