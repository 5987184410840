import styled from 'styled-components';

export const WidgetsGrid = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  height: 100%;
  margin-top: 16px;
  overflow-y: auto;
  padding: 4px;

  & > div {
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
  }

  @media (min-width: ${({ theme }) => theme.mediaQueries.lg}) {
    grid-template-columns: repeat(6, 1fr);
    display: grid;
    margin: unset;
  }
`;

export const Wrapper = styled.div<{ isstudent: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
  max-width: ${({ isstudent }) => (isstudent ? '1200px' : '100%')};
  margin: 0 auto;

  & > :first-child {
    margin-bottom: 8px; !important;
  }
`;
